import React, { useState } from "react";
import { AppBar, Toolbar, Button, Box } from "@mui/material";
import AutoApplySettings from "./AutoApplySettings";
import AutoApplyStatistics from "./AutoApplyStatistics";
import AutoApplyTrigger from "./AutoApplyTrigger";
import AutoApplyPricingPage from "./AutoApplyPricingPage";

const Navbar = () => {
  // State to manage which component to display
  const [activeComponent, setActiveComponent] = useState("trigger");

  // Function to render the appropriate component
  const renderComponent = () => {
    switch (activeComponent) {
      case "trigger":
        return <AutoApplyTrigger />;
      case "statistics":
        return <AutoApplyStatistics />;
      case "settings":
        return <AutoApplySettings />;
      case "pricing":
        return <AutoApplyPricingPage />;
      default:
        return <AutoApplyTrigger />;
    }
  };

  return (
    <Box
      sx={{
        width: "100vw",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        minHeight: "100vh",
      }}
    >
      <AppBar position="static">
        <Toolbar>
          <Button color="inherit" onClick={() => setActiveComponent("trigger")}>
            Trigger
          </Button>
          <Button
            color="inherit"
            onClick={() => setActiveComponent("statistics")}
          >
            Statistics
          </Button>
          <Button
            color="inherit"
            onClick={() => setActiveComponent("settings")}
          >
            Settings
          </Button>
          <Button color="inherit" onClick={() => setActiveComponent("pricing")}>
            Pricing
          </Button>
        </Toolbar>
      </AppBar>

      {/* Render the selected component */}
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flex: 1,
          width: "100%",
        }}
      >
        {renderComponent()}
      </Box>
    </Box>
  );
};

export default Navbar;
