import React, { useState, useEffect } from "react";
import { Typography } from "@mui/material";

interface TimerProps {
  timeLeft: number;
  setTimeLeft: any;
}

const Timer: React.FC<TimerProps> = ({ timeLeft, setTimeLeft }) => {
  useEffect(() => {
    if (timeLeft > 0) {
      const timerId = setInterval(() => {
        setTimeLeft((prev: any) => Math.max(prev - 1, 0)); // Decrement time by 1 every second
      }, 1000);

      return () => clearInterval(timerId); // Cleanup interval on unmount
    }
  }, [timeLeft]); // Run this effect when `timeLeft` changes

  // Convert seconds to HH:MM:SS
  const formatTime = (secs: number) => {
    const hours = Math.floor(secs / 3600)
      .toString()
      .padStart(2, "0");
    const minutes = Math.floor((secs % 3600) / 60)
      .toString()
      .padStart(2, "0");
    const seconds = (secs % 60).toString().padStart(2, "0");
    return `${hours}:${minutes}:${seconds}`;
  };

  return (
    <Typography
      variant="h3"
      sx={{
        color: "#fff",
        fontWeight: "bold",
        textAlign: "center",
        fontFamily: "'Roboto', sans-serif",
        letterSpacing: "0.05em",
        padding: "10px",
        background: "transparent",
        borderRadius: "8px",
        textShadow: "2px 2px 4px rgba(0, 0, 0, 0.5)",
      }}
    >
      {formatTime(timeLeft)} {/* Use `timeLeft` instead of `seconds` */}
    </Typography>
  );
};

export default Timer;
