import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Grid,
  Typography,
  Card,
  CardMedia,
} from "@mui/material";
import { getValue, updateKeyValue } from "../api/Redis";

const CaptchaBypass = ({ imageFile, options, open, onClose }) => {
  const [selectedOption, setSelectedOption] = useState(null);

  const handleOptionSelect = (option) => {
    setSelectedOption(option);
  };

  const handleSubmit = () => {
    let email = localStorage.getItem("email");
    const changesDict = {
      captchaResponseAnswer: selectedOption.Button,
      captchaResponseTime: Date.now() / 1000,
    };
    const response = updateKeyValue(email, changesDict);
    onClose(); // Close the dialog
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Captcha Bypass</DialogTitle>
      <DialogContent>
        {/* Display the PNG image file */}
        {imageFile && (
          <Card sx={{ marginBottom: 2 }}>
            <CardMedia
              component="img"
              image={imageFile} // Use the imageFile directly since it's a URL
              alt="Captcha"
              sx={{
                objectFit: "cover", // Ensures the image covers the area
                height: "200px", // Set the height according to your design
                width: "100%", // Set the width to fit the container
                transform: "scale(4.0)", // Adjust scale for zooming effect
                transformOrigin: "center", // Set the transform origin to the center
                transformOrigin: "center 28%", // Shift the zoom origin downward
              }}
            />
          </Card>
        )}
        <Typography variant="body1" sx={{ marginBottom: 2 }}>
          Please select the correct option based on the image:
        </Typography>
        <Grid container spacing={2}>
          {options.map((option, index) => (
            <Grid item xs={2} key={index}>
              <Button
                variant={selectedOption === option ? "contained" : "outlined"}
                onClick={() => handleOptionSelect(option)}
                sx={{ width: "100%" }}
              >
                {option}
              </Button>
            </Grid>
          ))}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary">
          Cancel
        </Button>
        <Button onClick={handleSubmit} color="primary">
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CaptchaBypass;
