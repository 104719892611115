import React from "react";
import { Box, Typography, Button, Container, Grid, Paper } from "@mui/material";
import { Link } from "react-router-dom";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import WorkIcon from "@mui/icons-material/Work";
import SentimentSatisfiedAltIcon from "@mui/icons-material/SentimentSatisfiedAlt";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

const AutoApplyHomePage = () => {
  return (
    <Box
      sx={{
        display: "flex",
        width: "100%",
        height: "100%",
        background: "url(/colorRainBackground.jpg)", // Purple-pink gradient
        boxSizing: "border-box",
      }}
    >
      <Container maxWidth="lg" sx={{ mt: 4 }}>
        {/* Header Section with Login/Signup Button */}
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            py: 2,
          }}
        >
          {/* Website Title */}
          <Typography variant="h3" fontWeight="bold" sx={{ color: "#ffffff" }}>
            LinkedIn Easy Apply Automation
          </Typography>
        </Box>
        {/* Login/Signup Button */}
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end", // Aligns children to the end of the container
            padding: "16px", // Optional: Add padding if needed
          }}
        >
          {/* Login/Signup Button */}
          <Button
            component={Link}
            to="/auth"
            variant="contained"
            size="large"
            sx={{
              marginLeft: "0px",
              borderRadius: "20px",
              px: 3,
              backgroundColor: "#ffffff",
              color: "#8e44ad", // Complementing purple
              "&:hover": {
                backgroundColor: "#e91e63",
              },
            }}
          >
            Login/Signup
          </Button>
        </Box>

        {/* Hero Section */}
        <Box
          sx={{
            textAlign: "center",
            py: 6,
          }}
        >
          <Typography
            variant="h2"
            fontWeight="bold"
            sx={{ color: "#ffffff" }}
            gutterBottom
          >
            Automate Your LinkedIn Easy Apply!
          </Typography>
          <Typography variant="h6" sx={{ color: "#f0f0f0" }} gutterBottom>
            Tired of applying again and again? We automate LinkedIn Easy Apply,
            and much more, for you!
          </Typography>
          <Button
            variant="contained"
            component={Link}
            to="/auth"
            size="large"
            sx={{ mt: 2, backgroundColor: "#ffffff", color: "#8e44ad" }}
          >
            Get Started
          </Button>
        </Box>

        {/* Feature Section */}
        <Box sx={{ py: 6 }}>
          <Typography
            variant="h4"
            align="center"
            fontWeight="bold"
            sx={{ color: "#ffffff" }}
            gutterBottom
          >
            Why Choose Us?
          </Typography>
          <Grid container spacing={4} justifyContent="center" sx={{ mt: 2 }}>
            {[
              {
                icon: <AutoAwesomeIcon sx={{ fontSize: 50 }} />,
                title: "Automated Apply",
                text: "Sit back and relax. Our system automates the whole process for LinkedIn Easy Apply.",
              },
              {
                icon: <TrendingUpIcon sx={{ fontSize: 50 }} />,
                title: "Resume Optimization",
                text: "We match your resume to job descriptions and apply for the best results.",
              },
              {
                icon: <WorkIcon sx={{ fontSize: 50 }} />,
                title: "Job Matching",
                text: "We continuously monitor idle server time and process your applications.",
              },
            ].map((feature, index) => (
              <Grid item xs={12} sm={6} md={4} key={index}>
                <Paper
                  elevation={3}
                  sx={{
                    p: 4,
                    textAlign: "center",
                    minHeight: "300px",
                    backgroundColor: "#ffffff",
                    color: "#8e44ad",
                  }}
                >
                  {feature.icon}
                  <Typography variant="h6" fontWeight="bold" sx={{ mt: 2 }}>
                    {feature.title}
                  </Typography>
                  <Typography sx={{ mt: 1 }}>{feature.text}</Typography>
                </Paper>
              </Grid>
            ))}
          </Grid>
        </Box>

        {/* How it Works Section */}
        <Box sx={{ py: 6, backgroundColor: "#f5f5f5" }}>
          <Typography
            variant="h4"
            align="center"
            fontWeight="bold"
            gutterBottom
            sx={{ color: "black" }}
          >
            How It Works
          </Typography>
          <Grid container spacing={4} justifyContent="center" sx={{ mt: 2 }}>
            {[
              {
                icon: <CheckCircleIcon sx={{ fontSize: 50 }} />,
                title: "Enroll",
                text: "Register and sit back while we handle your applications.",
              },
              {
                icon: <SentimentSatisfiedAltIcon sx={{ fontSize: 50 }} />,
                title: "Solve Captcha Once",
                text: "A one-time captcha solving keeps your account bot-proof.",
              },
              {
                icon: <AutoAwesomeIcon sx={{ fontSize: 50 }} />,
                title: "Continuous Application",
                text: "We apply on your behalf when the server is idle.",
              },
              {
                icon: <TrendingUpIcon sx={{ fontSize: 50 }} />,
                title: "Statistics & Tracking",
                text: "Track all your applications and modified resumes in one place.",
              },
            ].map((step, index) => (
              <Grid item xs={12} sm={6} md={3} key={index}>
                <Paper
                  elevation={2}
                  sx={{
                    p: 4,
                    textAlign: "center",
                    minHeight: "300px",
                    backgroundColor: "#ffffff",
                    color: "#8e44ad",
                  }}
                >
                  {step.icon}
                  <Typography variant="h6" fontWeight="bold" sx={{ mt: 2 }}>
                    {step.title}
                  </Typography>
                  <Typography sx={{ mt: 1 }}>{step.text}</Typography>
                </Paper>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Container>
    </Box>
  );
};

export default AutoApplyHomePage;
