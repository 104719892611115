import React, { useState, useContext } from "react";
import { Box, Card, TextField, Button, Typography } from "@mui/material";
import Authentication from "../../Authentication/Authentication";

const images = [
  {
    imgName: "correctresume.png",
    imgText: "Our Model Improve Your Resume Before Applying",
  },
  {
    imgName: "oneclick.png",
    imgText: "Just One click, Apply to all Easy-Apply",
  },
  {
    imgName: "filterbased.png",
    imgText: "Filter Based Job Application, Increases Your Chance",
  },
];

const displayMessage = "Hi there, welcome to Auto Improved Apply.  ";

const AutoApplyAuthPage = () => {
  return (
    <Box>
      <Authentication images={images} displayMessage={displayMessage} />
    </Box>
  );
};

export default AutoApplyAuthPage;
