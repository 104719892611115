import React from "react";
import { Route, Routes } from "react-router-dom";
import PrivateRoute from "./PrivateRoute";
import AutoApplyAuthPage from "./pages/apps/autoApply/AutoApplyAuthPage";
import Layout from "./common/Layout";
import AutoApplyMainPage from "./pages/apps/autoApply/AutoApplyMainPage";
import ContactPage from "./pages/ContactPage";
import AboutPage from "./pages/AboutPage";
import AutoApplyHomePage from "./pages/apps/autoApply/AutoApplyHomePage";

const App: React.FC = () => {
  return (
    <Layout>
      <Routes>
        <Route path="/contact" Component={ContactPage}></Route>
        <Route path="/about" Component={AboutPage}></Route>
        <Route path="/auth" Component={AutoApplyAuthPage}></Route>
        <Route path="/" Component={AutoApplyHomePage}></Route>
        <Route path="/home" Component={AutoApplyHomePage}></Route>
        <Route
          path="/auth/aamain"
          element={<PrivateRoute element={AutoApplyMainPage} />}
        ></Route>
      </Routes>
    </Layout>
  );
};

export default App;
