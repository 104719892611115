import React, { useState, ChangeEvent, useEffect } from "react";
import {
  Button,
  Typography,
  Card,
  CardContent,
  CardActions,
  Grid,
  Box,
} from "@mui/material";
import { saveFile } from "../../../api/RapidData";
import LoaderCenter from "../../../common/LoaderCenter";
import { retrieveAutoApplyUserData } from "../../../api/RapidData";
import { triggerAutoApply, downloadFileS3Blob } from "../../../api/RapidData";
import TypingGreetings from "../../../common/TypingGreetings";
import { getValue } from "../../../api/Redis";
import CaptchaBypass from "../../../common/CaptchaBypass";
import Timer from "../../../common/Timer";
import NoticePopup from "../../../common/NoticePopup";
import { DateTime } from "luxon";

const options = ["1", "2", "3", "4", "5", "6"];

const AutoApplyTrigger: React.FC = () => {
  const [loadingMessage, setLoadingMessage] = useState("Loading...");
  const [latexFile, setLatexFile] = useState<File | null>(null);
  const [isButtonDisabled, setIsButtonDisabled] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [userResume, setUserResume] = useState<string>("");
  const [fileName, setFileName] = useState<string>("No file chosen"); // State for file name
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [imageFile, setImageFile] = useState<string | void | null>(null);
  const [timeLeft, setTimeLeft] = useState<number>(60);
  const [displayNotice, setDisplayNotice] = useState(false);
  const [noticeMessage, setNoticeMessage] = useState(
    "OOps something went wrong",
  );

  useEffect(() => {
    setLoading(true);
    const getSettings = async () => {
      try {
        const response: any = await retrieveAutoApplyUserData();
        if (response.status === 200) {
          const { email, userResume, isPremium, lastTriggerTime } =
            response.data;
          setUserResume(userResume);

          const year = lastTriggerTime[0];
          const month = lastTriggerTime[1];
          const day = lastTriggerTime[2];
          const hour = lastTriggerTime[3];
          const minute = lastTriggerTime[4];
          const second = lastTriggerTime[5];

          console.log(year, month, day, hour, minute, second)
          const parsedDate = DateTime.fromObject(
            {
              year,
              month,
              day,
              hour,
              minute,
              second,
            },
            { zone: "Asia/Kolkata" },
          );

          // Add 30 minutes to the IST date
          const futureDate: any = parsedDate.plus({ minutes: 30 });

          // Convert current date to IST
          const currentDate: any = DateTime.now().setZone("Asia/Kolkata");

          console.log(futureDate);
          console.log(currentDate);
          console.log(parsedDate);
          console.log(futureDate.toJSDate());
          console.log(currentDate.toJSDate());
          console.log(parsedDate.toJSDate());

          let remainingTimeInSec = 0;
          if (futureDate.toJSDate() > currentDate.toJSDate()) {
            remainingTimeInSec =
              Math.ceil(
                (futureDate.toJSDate() - currentDate.toJSDate()) / (60 * 1000),
              ) * 60;
            setTimeLeft(remainingTimeInSec);
            setIsButtonDisabled(true);
          } else {
            setTimeLeft(0);
            setIsButtonDisabled(false);
          }

          // Pre-fill file input
          if (userResume) {
            setLatexFile(new File([], userResume)); // Create a dummy File object
            setFileName(userResume); // Set the file name
          }
          checkButtonState(new File([], userResume)); // Check button state
        }
      } catch (error) {
        console.error("Error:", error);
      } finally {
        setLoading(false);
      }
    };
    getSettings();
  }, [noticeMessage, displayNotice]);

  useEffect(() => {
    if (timeLeft === 0) {
      setIsButtonDisabled(false);
    }
  }, [timeLeft]);

  const handleFileChange = async (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files ? e.target.files[0] : null;
    if (file) {
      setLatexFile(file);
      setFileName(file.name); // Update the file name state
      checkButtonState(file);

      setLoading(true);
      setError(null);
      try {
        await saveFile(file.name, file, "user-resume");
        // Handle success, e.g., display a success message
      } catch (err) {
        setError("Failed to upload file. Please try again.");
      } finally {
        setLoading(false);
      }
    }
  };

  // Enable button if either file or details are provided
  const checkButtonState = (file: File | null) => {
    if (file && timeLeft < 1) {
      setIsButtonDisabled(false);
    }
  };

  // Function to trigger file input click
  const handleCustomButtonClick = () => {
    document.getElementById("file-input")?.click();
  };

  const handleTriggerButton = async () => {
    try {
      // Create a fake delay
      setLoading(true);
      const delay = (ms: any) =>
        new Promise((resolve) => setTimeout(resolve, ms));

      // Calling Auto Trigger ApI
      const response: any = await triggerAutoApply();
      // If response is OK
      if (response === "triggered successfully") {
        setLoadingMessage("Bot Checking...");
        let retries = 10;
        while (retries > 0) {
          await delay(10000);
          const email: string = localStorage.getItem("email") || "";
          const redis_response_raw = await getValue(email);
          const redis_response = redis_response_raw?.[email];

          // If redis response has OTP
          if (redis_response?.haveOTP) {
            setLoadingMessage("OTP screen Loading...");
          }

          // If redis response has captcha Create Puzzle
          if (redis_response?.haveCaptcha) {
            setLoadingMessage("Creating Puzzle...");
            const pngFile = await downloadFileS3Blob(email, "captcha");
            setImageFile(pngFile);
            setOpen(true);
          }

          // If Login success
          const loginMessage = redis_response?.login;
          if (loginMessage == "SUCCESS") {
            setNoticeMessage("SIT BACK AND RELAX, REST WE WILL DO FOR YOU");
            break;
          }
          retries -= 1;
        }
        if (retries == 0) {
          setNoticeMessage("OOps something Went Wrong");
        }
        setDisplayNotice(true);
      }
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box>
      <CaptchaBypass
        imageFile={imageFile}
        options={options}
        open={open}
        onClose={handleClose}
      />
      <Timer timeLeft={timeLeft} setTimeLeft={setTimeLeft} />
      {displayNotice && (
        <NoticePopup
          message={noticeMessage}
          displayNotice={displayNotice}
          setDisplayNotice={setDisplayNotice}
        />
      )}
      <Box
        sx={{
          position: "absolute",
          top: "12%",
          right: "20%",
          padding: 2, // Add some padding to avoid touching the edges
          zIndex: 1000, // Ensure it stays on top of other content
        }}
      >
        <TypingGreetings
          duration={15000}
          text={"Hello " + (localStorage.getItem("name") ?? "") + "!"}
        />
      </Box>
      <Grid container justifyContent="center" sx={{ marginTop: "50px" }}>
        {loading && <LoaderCenter message={loadingMessage} />}
        <Card
          sx={{
            maxWidth: 600,
            padding: 3,
            boxShadow: "0px 8px 20px rgba(0,0,0,0.1)",
            borderRadius: "15px",
            backgroundColor: "#f9f9f9",
          }}
        >
          <CardContent>
            <Typography
              variant="h5"
              component="div"
              sx={{ fontWeight: "bold", marginBottom: 2 }}
            >
              Upload Your Resume
            </Typography>

            <input
              type="file"
              accept=".pdf"
              id="file-input"
              onChange={handleFileChange}
              style={{ display: "none" }} // Hide the default file input
            />
            <Button
              variant="contained"
              onClick={handleCustomButtonClick}
              sx={{
                marginBottom: 2,
                color: "#fff",
                padding: "10px 20px",
                borderRadius: "25px",
                fontWeight: "bold",
                fontSize: "18px",
                textTransform: "none",
                boxShadow: "0px 5px 15px rgba(0,0,0,0.2)",
                transition: "0.3s",
                "&:hover": {
                  backgroundColor: "#66bb6a",
                  boxShadow: "0px 8px 20px rgba(0,0,0,0.3)",
                },
                "&:active": { boxShadow: "none", backgroundColor: "#388e3c" },
              }}
            >
              Choose File
            </Button>

            {/* Display the name of the selected file */}
            <Typography variant="body2" sx={{ marginBottom: 1 }}>
              {fileName}
            </Typography>

            {error && <Typography color="error">{error}</Typography>}
          </CardContent>

          <CardActions sx={{ justifyContent: "center" }}>
            <Button
              onClick={handleTriggerButton}
              variant="contained"
              disabled={isButtonDisabled || loading}
              sx={{
                backgroundColor: isButtonDisabled ? "#ccc" : "#4caf50",
                color: "#fff",
                padding: "10px 20px",
                borderRadius: "25px",
                fontWeight: "bold",
                fontSize: "18px",
                textTransform: "none",
                boxShadow: "0px 5px 15px rgba(0,0,0,0.2)",
                transition: "0.3s",
                "&:hover": {
                  backgroundColor: isButtonDisabled ? "#ccc" : "#66bb6a",
                  boxShadow: isButtonDisabled
                    ? "none"
                    : "0px 8px 20px rgba(0,0,0,0.3)",
                },
                "&:active": {
                  boxShadow: "none",
                  backgroundColor: isButtonDisabled ? "#ccc" : "#388e3c",
                },
              }}
            >
              Trigger
            </Button>
          </CardActions>
        </Card>
      </Grid>
    </Box>
  );
};

export default AutoApplyTrigger;
