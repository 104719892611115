import React, { useState, ChangeEvent, useEffect } from "react";
import {
  Box,
  TextField,
  Button,
  Checkbox,
  FormGroup,
  FormControlLabel,
  Typography,
  Grid,
  SelectChangeEvent,
} from "@mui/material";
import { save_settings, retrieve_settings } from "../../../api/RapidData";
import LoaderCenter from "../../../common/LoaderCenter";

interface FormValues {
  email: string;
  password: string;
  role: string;
  country: string;
  experience: string[];
}

const AutoApplyMainPage: React.FC = () => {
  const [formValues, setFormValues] = useState<FormValues>({
    email: "",
    password: "",
    role: "",
    country: "",
    experience: [],
  });

  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [showLoader, setShowLoader] = useState<boolean>(false);

  useEffect(() => {
    setShowLoader(true);
    const getSettings = async () => {
      try {
        const response: any = await retrieve_settings();
        if (response.status === 200) {
          const {
            userEmail,
            linkedinEmailId,
            linkedinPassword,
            role,
            country,
            exp1,
            exp2,
          } = response.data;

          setFormValues({
            email: linkedinEmailId || "",
            password: linkedinPassword || "",
            role: role || "",
            country: country || "",
            experience: [exp1, exp2] || [],
          });
        }
      } catch (error) {
        // handle error
        console.error("Error:", error);
      } finally {
        setShowLoader(false);
      }
    };
    getSettings();
  }, []);

  // Separate handler for TextField
  const handleTextFieldChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  // Separate handler for Select component
  const handleSelectChange = (e: SelectChangeEvent<string>) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name as string]: value,
    });
  };

  const handleExperienceChange = (event: ChangeEvent<HTMLInputElement>) => {
    const selectedExperience = event.target.name;
    const newExperience = formValues.experience.includes(selectedExperience)
      ? formValues.experience.filter((item) => item !== selectedExperience)
      : [...formValues.experience, selectedExperience];

    if (newExperience.length <= 2) {
      setFormValues({ ...formValues, experience: newExperience });
    }
  };

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handleSaveClick = () => {
    setIsEditing(false);
    setShowLoader(true);
    const linkedinRequest = {
      linkedinEmailId: formValues?.email,
      linkedinPassword: formValues?.password,
      role: formValues?.role,
      country: formValues?.country,
      exp1: formValues?.experience?.[0],
      exp2: formValues?.experience?.[1],
    };

    save_settings(linkedinRequest)
      .then((response: any) => {
        if (response.status == 200) {
          // do nothing
        }
        setShowLoader(false);
      })
      .catch((error) => {
        setShowLoader(false);
      });
  };

  return (
    <Box
      sx={{
        maxWidth: 500,
        margin: "0px 20px 0px 20px",
        padding: 4,
        boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
        borderRadius: 2,
        backgroundColor: "#fff",
      }}
    >
      {showLoader && <LoaderCenter message="loading..." />}
      <Typography variant="h4" gutterBottom align="center">
        Settings
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="email"
            name="email"
            value={formValues.email}
            onChange={handleTextFieldChange} // Using the TextField handler
            disabled={!isEditing}
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            fullWidth
            type="password"
            label="Linkedin Password"
            name="password"
            value={formValues.password}
            onChange={handleTextFieldChange} // Using the TextField handler
            disabled={!isEditing}
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Role"
            name="role"
            value={formValues.role}
            onChange={handleTextFieldChange}
            disabled={!isEditing}
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Country"
            name="country"
            value={formValues.country}
            onChange={handleTextFieldChange} // Using the TextField handler
            disabled={!isEditing}
          />
        </Grid>

        <Grid item xs={12}>
          <Typography variant="subtitle1" gutterBottom>
            Experience (Select up to 2):
          </Typography>
          <FormGroup>
            {[
              "Internship",
              "Entry level",
              "Associate",
              "Mid-Senior level",
              "Director",
              "Executive",
            ].map((exp) => (
              <FormControlLabel
                key={exp}
                control={
                  <Checkbox
                    checked={formValues.experience.includes(exp)}
                    onChange={handleExperienceChange}
                    name={exp}
                    disabled={!isEditing}
                  />
                }
                label={exp}
              />
            ))}
          </FormGroup>
        </Grid>

        <Grid item xs={12} container justifyContent="space-between">
          <Button
            variant="contained"
            color="primary"
            onClick={handleEditClick}
            disabled={isEditing}
          >
            Edit
          </Button>
          <Button
            variant="contained"
            color="secondary"
            onClick={handleSaveClick}
            disabled={!isEditing}
          >
            Save
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

export default AutoApplyMainPage;
