import React, { useState, useEffect, useContext, useRef } from "react";
import { Box, Input, Button, Typography } from "@mui/material";
import { otpCall } from "../../api/RapidData";
import { AuthContext } from "../../api/AuthProvider";
import { useNavigate } from "react-router-dom";

interface SignUpGateProps {
  password: string;
  email: string;
  name: string;
  setShowLoader: any;
  setShowOtpScreen: any;
}

const SignUpGate: React.FC<SignUpGateProps> = ({
  password,
  email,
  name,
  setShowLoader,
  setShowOtpScreen,
}) => {
  const [timer, setTimer] = useState(300);
  const [resenderTimer, setResenderTimer] = useState(30);
  const [otp, setOtp] = useState(Array(6).fill(""));
  const inputRefs = useRef<(HTMLInputElement | null)[]>([]);
  const { signup } = useContext(AuthContext);
  const navigate = useNavigate();

  useEffect(() => {
    const countdown = setInterval(() => {
      setResenderTimer((prevTimer) => (prevTimer > 0 ? prevTimer - 1 : 0));
      setTimer((prevTimer) => (prevTimer > 0 ? prevTimer - 1 : 0));
    }, 1000);

    return () => clearInterval(countdown);
  }, []);

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number,
  ) => {
    const { value } = e.target;
    if (/^[0-9]?$/.test(value)) {
      const newOtp = [...otp];
      newOtp[index] = value;
      setOtp(newOtp);

      // Move to next input field
      if (value && index < inputRefs.current.length - 1) {
        inputRefs.current[index + 1]?.focus();
      }
    }
  };

  const handleSubmit = () => {
    setShowLoader(true);
    const otpString = otp.join("");
    signup(email, password, name, otpString)
      .then((response: any) => {
        if (response.status == 200) {
          navigate("/auth/aamain");
          setShowOtpScreen(false);
        } else {
          setShowOtpScreen(false);
          setShowLoader(false);
        }
      })
      .catch((error: any) => {
        setShowLoader(false);
        setShowOtpScreen(false);
      });
  };

  const handleResender = () => {
    otpCall(email)
      .then((response: any) => {
        if (response.status == 200) {
          setShowOtpScreen(true);
        } else {
          setShowOtpScreen(false);
        }
        setShowLoader(false);
      })
      .catch((error: any) => {
        setShowLoader(false);
        setShowOtpScreen(false);
      });
    setTimer(300);
    setResenderTimer(30);
  };

  const formatTime = (time: number) => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${minutes}:${seconds < 10 ? `0${seconds}` : seconds}`;
  };

  return (
    <Box sx={sxs.signUpGate}>
      <h2>An OTP has been sent.</h2>
      <Box
        sx={{
          ...sxs.timer,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {formatTime(timer)}
      </Box>
      <Box sx={sxs.otpContainer}>
        {otp.map((digit, index) => (
          <Input
            key={index}
            type="text"
            value={digit}
            inputRef={(el) => (inputRefs.current[index] = el)}
            onChange={(e: any) => handleChange(e, index)}
            sx={sxs.otpInput}
          />
        ))}
      </Box>
      <Button onClick={handleSubmit} sx={sxs.verifyButton}>
        Verify
      </Button>
      <Box
        sx={{
          ...sxs.timer,
          display: "flex",
          justifyContent: "end",
          alignItems: "center",
          marginBottom: "-10px",
        }}
      >
        <Button
          disabled={resenderTimer != 0}
          onClick={handleResender}
          variant="text"
        >
          Resend OTP{" "}
        </Button>
        <Typography sx={{ fontSize: "15px" }}>
          {formatTime(resenderTimer)}
        </Typography>
      </Box>
    </Box>
  );
};

const sxs = {
  signUpGate: {
    position: "fixed",
    top: "50%",
    left: "50%",
    backgroundColor: "white",
    transform: "translate(-50%, -50%)",
    borderRadius: "40px",
    zIndex: 500, // High z-index to be on top of everything
    padding: "20px", // Optional: padding for content
    boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)", // Optional: shadow for better visibility
  },
  timer: {
    fontSize: "24px",
    marginBottom: "20px",
  },
  otpContainer: {
    display: "flex",
    justifyContent: "center",
    marginBottom: "20px",
  },
  otpInput: {
    width: "40px",
    height: "40px",
    margin: "0 5px",
    textAlign: "center",
    fontSize: "24px",
    border: "1px solid #ccc",
    borderRadius: "4px",
  },
  verifyButton: {
    padding: "8px 16px",
    fontSize: "16px",
    color: "#fff",
    backgroundColor: "#007bff",
    border: "none",
    borderRadius: "4px",
    cursor: "pointer",
    margin: "0 auto",
    display: "block",
    "&:hover": {
      backgroundColor: "#0056b3",
    },
  },
};

export default SignUpGate;
