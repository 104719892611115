// components/Layout.js
import React from "react";
import Navbar from "./Navbar";
import Footer from "./Footer";
import { Container } from "@mui/material";

const Layout = ({ children }) => (
  <div>
    <Navbar />
    <Container>{children}</Container>
    <Footer />
  </div>
);

export default Layout;
