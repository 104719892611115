import axios from "axios";

const api = axios.create({
  baseURL: process.env.REACT_APP_RAPID_DATA,
});

// Utility function to introduce delay
const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

// Minimum 3-second delay function
const withMinDelay = async (apiCallPromise, minDelay = 1000) => {
  const startTime = Date.now();

  // Execute the API call
  const response = await apiCallPromise;

  const elapsedTime = Date.now() - startTime;
  const remainingTime = minDelay - elapsedTime;

  // Wait if the API call finished before 3 seconds
  if (remainingTime > 0) {
    await delay(remainingTime);
  }

  return response;
};

// auto-apply
/////////////////////////////////////////////////////////////////////////////

// Save settings API
const save_settings = async (linkedinDetailsRequest) => {
  const token = localStorage.getItem("token");

  try {
    // Wrap the API call with minimum delay
    return await withMinDelay(
      api.post(
        `/api/v1/rapid-data/auto_apply/save_settings`,
        linkedinDetailsRequest,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      ),
    );
  } catch (error) {
    console.error("Error saving settings:", error);
    return false;
  }
};

// Retrieve settings API
const retrieve_settings = async () => {
  const token = localStorage.getItem("token");

  try {
    // Wrap the API call with minimum delay
    return await withMinDelay(
      api.get("/api/v1/rapid-data/auto_apply/retrieve_settings", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }),
    );
  } catch (error) {
    console.error("Error retrieving settings:", error);
    return false;
  }
};

// Retrieve settings API
const retrieveAutoApplyUserData = async () => {
  const token = localStorage.getItem("token");
  try {
    // Wrap the API call with minimum delay
    return await withMinDelay(
      api.get("/api/v1/rapid-data/auto_apply/retrieve_aa_user_data", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }),
    );
  } catch (error) {
    console.error("Error retrieving settings:", error);
    return false;
  }
};

const otpCall = async (email) => {
  try {
    return await api.post("/api/v1/auth/otp", { email });
  } catch (error) {
    return false;
  }
};

const saveFile = async (fileName, file, bucketName) => {
  try {
    // Create FormData to handle file uploads
    const token = localStorage.getItem("token");

    const formData = new FormData();
    formData.append("fileName", fileName);
    formData.append("file", file);
    formData.append("bucketName", bucketName);

    // Send POST request with FormData
    const response = await api.post(
      "/api/v1/logical/auto_apply/upload-file",
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
      },
    );

    // Handle and return the response
    return response.data;
  } catch (error) {
    // Improve error handling with logging and rethrowing
    console.error(
      "Error uploading file:",
      error.response || error.message || error,
    );
    throw error; // Rethrow error after logging it
  }
};

const triggerAutoApply = async () => {
  try {
    // Create FormData to handle file uploads
    const userEmail = localStorage.getItem("userEmail");
    const token = localStorage.getItem("token");

    // Send POST request with FormData
    const response = await api.get("/api/v1/logical/auto_apply/trigger", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    // Handle and return the response
    return response.data;
  } catch (error) {
    // Improve error handling with logging and rethrowing
    console.error(
      "Error while triggering:",
      error.response || error.message || error,
    );
    throw error; // Rethrow error after logging it
  }
};

const usersAllFile = async () => {
  try {
    const token = localStorage.getItem("token");

    const response = await api.get(
      "/api/v1/logical/auto_apply/users_all_file",
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );
    // Handle and return the response
    return response.data;
  } catch (error) {
    // Improve error handling with logging and rethrowing
    console.error(
      "Error while triggering:",
      error.response || error.message || error,
    );
    throw error; // Rethrow error after logging it
  }
};

const downloadFileS3Blob = async (fileName, bucketName) => {
  try {
    const token = localStorage.getItem("token");

    // Send GET request with 'blob' response type
    const response = await api.get(
      "/api/v1/logical/auto_apply/download_user_resume",
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          fileName,
          bucketName,
        },
        responseType: "blob", // Important to handle binary data properly
      },
    );

    // Create a Blob URL from the response data
    const url = window.URL.createObjectURL(new Blob([response.data]));

    // Return the URL so it can be used in the component
    return url;
  } catch (error) {
    console.error("Error downloading the file", error);
    return null; // Return null in case of an error
  }
};

const downloadFileS3 = async (fileName, bucketName) => {
  try {
    const token = localStorage.getItem("token");

    // Send POST request with FormData
    const response = await api.get(
      "/api/v1/logical/auto_apply/download_user_resume",
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          fileName,
          bucketName,
        },
      },
    );

    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", fileName + ".pdf");
    document.body.appendChild(link);
    link.click();
    link.remove();
  } catch (error) {
    console.error("Error downloading the file", error);
  }
};

export {
  saveFile,
  otpCall,
  triggerAutoApply,
  usersAllFile,
  downloadFileS3,
  downloadFileS3Blob,
  save_settings,
  retrieve_settings,
  retrieveAutoApplyUserData,
};
