import React from "react";

const Loader = ({ message }) => {
  const size = "300px";
  const duration = "2s";
  const background =
    "linear-gradient(0deg, rgba(50,50,50,0.2) 0%, rgba(100,100,100,0.2) 100%)";

  const loaderStyle = {
    height: size,
    aspectRatio: 1,
    position: "relative",
    zIndex: 1000, // Ensuring the loader stays on top of other components
  };

  const boxStyle = {
    position: "absolute",
    background: "rgba(100, 100, 100,0.15)",
    background,
    borderRadius: "50%",
    borderTop: "1px solid rgba(100,100,100,1)",
    boxShadow: "rgba(0, 0, 0, 0.3) 0px 10px 10px -0px",
    backdropFilter: "blur(5px)",
    animation: `ripple ${duration} infinite ease-in-out`,
  };

  const logoStyle = {
    position: "absolute",
    inset: 0,
    display: "grid",
    placeContent: "center",
    padding: "30%",
    color: "white", // Ensuring the text color is correct
    zIndex: 1001, // Ensuring the text stays above the loader circles
  };

  const boxes = [
    { inset: "40%", zIndex: 999 },
    {
      inset: "30%",
      zIndex: 998,
      borderColor: "rgba(100,100,100,0.8)",
      animationDelay: "0.2s",
    },
    {
      inset: "20%",
      zIndex: 997,
      borderColor: "rgba(100,100,100,0.6)",
      animationDelay: "0.4s",
    },
    {
      inset: "10%",
      zIndex: 996,
      borderColor: "rgba(100,100,100,0.4)",
      animationDelay: "0.6s",
    },
    {
      inset: 0,
      zIndex: 995,
      borderColor: "rgba(100,100,100,0.2)",
      animationDelay: "0.8s",
    },
  ];

  return (
    <div style={loaderStyle} className="loader-container">
      {boxes.map((box, index) => (
        <div
          key={index}
          style={{
            ...boxStyle,
            inset: box.inset,
            zIndex: box.zIndex,
            borderColor: box.borderColor,
            animationDelay: box.animationDelay,
          }}
          className={`loader-box box-${index}`}
        ></div>
      ))}
      <div style={logoStyle} className="loader-logo">
        <div style={{ fontWeight: "bold", fontSize: "20px" }}>
          {message || "Loading..."}
        </div>
      </div>
    </div>
  );
};

const rippleKeyframes = `
@keyframes ripple {
  0% {transform: scale(1); box-shadow: rgba(0, 0, 0, 0.3) 0px 10px 10px -0px; }
  50% {transform: scale(1.3); box-shadow: rgba(0, 0, 0, 0.3) 0px 30px 20px -0px;}
  100% { transform: scale(1); box-shadow: rgba(0, 0, 0, 0.3) 0px 10px 10px -0px;}
}`;

const colorChangeKeyframes = `
@keyframes color-change {
  0% { fill: white; }
  50% { fill: white; }
  100% { fill: white; }
}`;

const styles = `
.loader-app {
  height: 0;
  display: grid;
  place-content: center;
  background: black;
}
${rippleKeyframes}
${colorChangeKeyframes}
`;

const LoaderApp = ({ message }) => (
  <>
    <style>{styles}</style>
    <div className="loader-app">
      <Loader message={message} />
    </div>
  </>
);

export default LoaderApp;
