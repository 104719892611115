import React from "react";
import {
  Container,
  Typography,
  Card,
  CardContent,
  Grid,
  Box,
} from "@mui/material";

const AboutPage = () => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        height: "100vh",
        backgroundImage: `url(/colorRainBackground.jpg)`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        boxSizing: "border-box",
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
      }}
    >
      <Container sx={{ mt: 5 }}>
        {/* Who We Are Section */}
        <Card sx={{ mb: 4 }}>
          <CardContent>
            <Typography variant="h4" component="div" gutterBottom>
              Who We Are?
            </Typography>
            <Typography variant="body1">
              We are a team of passionate individuals with a shared vision to
              make the world a better place. Our team consists of great minds
              from diverse fields, working collaboratively to create innovative
              solutions to global challenges. We believe that through
              creativity, perseverance, and a strong sense of responsibility, we
              can drive positive change. By leveraging technology and
              human-centric approaches, we continuously strive to empower
              communities, improve lives, and shape a brighter, sustainable
              future for generations to come.
            </Typography>
          </CardContent>
        </Card>
      </Container>
    </Box>
  );
};

export default AboutPage;
