import axios from "axios";

const api = axios.create({
  baseURL: process.env.REACT_APP_RAPID_DATA,
});

// Function to retrieve the token from localStorage
const getToken = () => localStorage.getItem("token");

// Function to set a key-value pair in Redis
const setKeyValue = async (redisKey, dictKeyValue) => {
  const token = getToken(); // Get the token

  try {
    const response = await api.post(
      "/logical-redis/set",
      {
        redisKey, // Send the redisKey
        dictKeyValue, // Send the dictionary of key-value pairs
      },
      {
        headers: {
          Authorization: `Bearer ${token}`, // Add the token to headers
        },
      },
    );
    return response?.data; // return the response data if needed
  } catch (error) {
    console.error("Error setting key-value:", error);
  }
};

// Function to get the value of a key in Redis
const getValue = async (key) => {
  const token = getToken(); // Get the token

  try {
    const response = await api.get("/logical-redis/get", {
      params: { key },
      headers: {
        Authorization: `Bearer ${token}`, // Add the token to headers
      },
    });
    return response?.data; // return the response data if needed
  } catch (error) {
    console.error("Error getting value:", error);
  }
};

// Function to update the value of a key in Redis
const updateKeyValue = async (redisKey, dictKeyValue) => {
  const token = getToken(); // Get the token

  try {
    const response = await api.post(
      "/logical-redis/update",
      {
        redisKey, // Send the redisKey
        dictKeyValue, // Send the dictionary of key-value pairs
      },
      {
        headers: {
          Authorization: `Bearer ${token}`, // Add the token to headers
        },
      },
    );
    return response?.data; // return the response data if needed
  } catch (error) {
    console.error("Error updating key-value:", error);
  }
};

export { getValue, setKeyValue, updateKeyValue };
