import React, { useState, useEffect } from "react";
import { usersAllFile, downloadFileS3 } from "../../../api/RapidData";
import LoaderCenter from "../../../common/LoaderCenter";

import {
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Box,
  Link,
  Button,
  Grid,
} from "@mui/material";

var plan = "Free";

function prepareUrl(jobId: any) {
  return (
    "https://www.linkedin.com/jobs/collections/recommended/?currentJobId=" +
    jobId
  );
}

const PlanComponent = () => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: "20px",
        backgroundColor: plan === "Premium" ? "#f0f4c3" : "#e0f7fa",
        borderRadius: "10px",
        boxShadow: 3,
        maxWidth: "90%",
        width: "100%",
        margin: "20px auto",
      }}
    >
      <Typography variant="h4" component="div" gutterBottom>
        Your Plan: {plan}
      </Typography>
      <Button
        variant="contained"
        color={plan === "Premium" ? "primary" : "secondary"}
      >
        {plan === "Premium" ? "Premium Plan" : "Free Plan"}
      </Button>
    </Box>
  );
};

const CompanyTable = ({ jobIds }: any) => {
  const handleDownloadButton = (event: any, jobId: string) => {
    event.preventDefault();
    downloadFileS3(jobId);
  };
  return (
    <TableContainer
      component={Paper}
      sx={{
        width: { xs: "100%", sm: "700px" },
        margin: "20px auto",
        boxShadow: 3,
      }}
    >
      <Table>
        <TableHead>
          <TableRow>
            <TableCell align="left">Company URL</TableCell>
            <TableCell align="left">Modified Resume</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {jobIds.map((jobId: any) => (
            <TableRow key={jobId.id}>
              <TableCell>
                <Link
                  href={prepareUrl(jobId.id)}
                  target="_blank"
                  rel="noopener"
                  color="primary"
                >
                  {jobId.id}
                </Link>
              </TableCell>
              <TableCell>
                <Link
                  href="temp"
                  onClick={(event) => handleDownloadButton(event, jobId.id)}
                  color="secondary"
                >
                  Download PDF
                </Link>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const AutoApplyStatistics = () => {
  const [showLoader, setShowLoader] = useState(true);
  const [jobIds, setJobIds] = useState([]);

  useEffect(() => {
    setShowLoader(true);
    const getAllFiles = async () => {
      try {
        const response = await usersAllFile();
        if (response?.all_files?.data) {
          setJobIds(response?.all_files?.data);
        }
      } catch (error) {
        console.error("Error:", error);
      } finally {
        setShowLoader(false);
      }
    };
    getAllFiles();
  }, []);

  return (
    <Grid container direction="column" alignItems="center">
      {showLoader && <LoaderCenter message={"Fetching ..."} />}
      <Grid item xs={12}>
        <PlanComponent />
      </Grid>
      <Grid item xs={12}>
        <CompanyTable jobIds={jobIds} />
      </Grid>
    </Grid>
  );
};

export default AutoApplyStatistics;
